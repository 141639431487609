import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import Container from 'components/layout/container';
import HTML from 'components/blocks/html';
import Layout from 'components/layout';
import PageTitle from 'components/ui/page_title';
import SEO from 'components/seo';

const Requisites = () => {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: {id: {eq: "overview"}}) {
        html
        frontmatter {
          seoTitle
          title
        }
      }
    }
  `);

  const { html, frontmatter: { seoTitle, title } } = data.markdownRemark;

  return (
    <Layout pageType="overview">
      <SEO title={seoTitle || title} />
      <Container>
        <PageTitle title={title} />
      </Container>


      <Container>
        <div className="page_container">
          <HTML>{html}</HTML>
        </div>
      </Container>

    </Layout>
  );
};


export default Requisites;
